import React from 'react';
import Head from './Head';

function Footer() {
    return (
        <footer className="footer mt-5 py-4 backFooter">
            <div className="container text-center">
                <div className="contact-info mt-4">

                    <div class="header-decorative">
                        <div class="container">
                            <div class="row">

                                <div class="col-md-6 text-end">
                                    <div class="wow-text2">
                                        <h2>CasaBlanca</h2>
                                        <h3>Kebab</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <h5>Contacto</h5>
                    <p>C. Alcalde Alfonso Martínez de la Hoz, 10<br /><a href="tel:632367041">Teléfono: 632367041</a>
                    </p>

                </div>

                <div className="opening-hours mt-4">
                    <h5>Horario</h5>
                    <p>Lun-Dom ........................................... 19:30-24:00</p>


                </div>
                <div></div>

            </div>
        </footer>
    );
}

export default Footer;
