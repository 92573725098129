import React from 'react';

function Head({ title, subtitle }) {
    const subtitleText = typeof subtitle === 'string' ? subtitle : '';
    return (
        <div class="header-decorative">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 text-start">
                        <div class="decor-left"></div>
                    </div>
                    <div class="col-md-6 text-end">
                        <div class="wow-text">
                            <h2>{title}</h2>
                            <h3>{subtitleText}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Head;
