import React from 'react';

function ItemList({ name, description, price }) {
  return (
    <li className="d-flex justify-content-between align-items-center mb-3">
      <div>
        <h4>{name}</h4>
        <p>{description}</p>
      </div>
      <span className="price">{price}€</span>
    </li>
  );
}

export default ItemList;
