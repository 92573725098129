import React from 'react';
import ItemList from '../components/ItemList';

function platosCombinados() {

  const platosCombinadosList = [
    { name: 'Sultán', description: 'Patatas gratinadas con kebab', price: '6,50' },
    { name: 'Ensalada de la Casa', description: 'Lechuga, tomate, cebolla, aceitunas, maíz, zanahoria, remolacha y atún', price: '5,00' },
    { name: 'Plato Combinado', description: 'Ensalada, patatas fritas, carne kebab y salsa yogourt', price: '6,50' }
  ];





  return (
    <div id="pizzas" className="menu-section">
      <div className="row">
        <div className="col-md-12">
          <ul className="list-unstyled">
            {platosCombinadosList.map((platocombinado, index) => (
              <ItemList
                key={index}
                name={platocombinado.name}
                description={platocombinado.description}
                price={platocombinado.price}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default platosCombinados;
