import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import '../cssComponents/Nav.css'; // Descomenta si necesitas estilos personalizados

// Array de objetos para los elementos de navegación
const navItems = [
  { id: 'home', label: 'Home', path: '/' },
  { id: 'pizzas', label: 'Pizzas', path: '/pizzas' },
  /*  { id: 'bocadillos', label: 'Bocadillos', path: '/bocadillos' }, */
  { id: 'menusKebab', label: 'Menus Kebab', path: '/menusKebab' },
  { id: 'menusPatatas', label: 'Menus Patatas', path: '/menusPatatas' },
  { id: 'platosCombinados', label: 'Platos Combinados', path: '/platosCombinados' }
];

function Nav() {
  /*Control cambio de color en btn*/
  /*Se recorre con un map en el return*/
  const [activeButton, setActiveButton] = useState('home');

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
  };

  return (
    <nav className="nav">
      <ul className="nav nav-pills justify-content-center mb-4" id="menu-tabs">
        {navItems.map(({ id, label, path }) => (
          <li key={id} className="nav-item">
            <Link
              to={path}
              className={activeButton === id ? 'active nav-link' : 'nav-link'}
              onClick={() => handleButtonClick(id)}
            >
              {label}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Nav;

