import React from 'react';
import ItemList from '../components/ItemList';

function MenusPatatas() {

  const menuTarrinaList = [
    { name: 'Pequeña', description: 'Con extra de carne y 1 bebida', price: '5,00' },
    { name: 'Mediana', description: 'Con extra de carne y 1 bebida', price: '6,00' },
    { name: 'Grande', description: 'Con extra de carne y 1 bebida', price: '7,00' }
  ];


  return (
    <div id="pizzas" className="menu-section">
      <div className="row">
        <div className="col-md-6">
          <ul className="list-unstyled">
            {menuTarrinaList.map((menu, index) => (
              <ItemList
                key={index}
                name={menu.name}
                description={menu.description}
                price={menu.price}
              />
            ))}
          </ul>
        </div>

      </div>
    </div>
  );
}

export default MenusPatatas;
