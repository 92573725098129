import React from 'react';
import Pizzas from './Pizzas';
import Bocadillos from './Bocadillos';
import MenusKebab from './MenusKebab';
import MenusPatatas from './MenusPatatas';

function Home() {
  return (
    <div>
      <h2 className="textH2">Menu Kebabs</h2>
      <MenusKebab />
      <h2 className="textH2">Menu Tarrinas</h2>
      <MenusPatatas />
      <h2 className="textH2">Pizzas</h2>
      <Pizzas />
    </div>
  );
}

export default Home;
