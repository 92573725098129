import React from 'react';
import ItemList from '../components/ItemList';
import { useCollectionData } from "react-firebase-hooks/firestore";
import { collection } from "firebase/firestore";
import { db } from "../firebase";

function Pizzas() {
  //Recupera los datos de la BBDD
  const [values, loading, error] = useCollectionData(collection(db, "Pizzas"), {
    idField: 'id'
  });

  //Divide en 2 columnas 
  let pizzaList1 = [];
  let pizzaList2 = [];
  if (values) {
    pizzaList1 = values.filter((_, index) => index % 2 === 0);
    pizzaList2 = values.filter((_, index) => index % 2 !== 0);

    //Lo pone en lo ultimo
    let index = pizzaList2.findIndex(item => item.Nombre === "Menu");
    if (index !== -1) {
      const [menuItem] = pizzaList2.splice(index, 1);
      pizzaList2.push(menuItem);
    }

  }
  //Muestra cargando hasta que este los datos
  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div id="pizzas" className="menu-section">
      <div className="row">
        {pizzaList1.map((value) => (

          <div className="col-md-6">
            <ul className="list-unstyled">
              <ItemList
                key={value.id}
                name={value.Nombre}
                description={value.Descripcion}
                price={value.Precio}
              />
            </ul>
          </div>
        ))}
        {pizzaList2.map((value, index) => (

          <div className="col-md-6">
            <ul className="list-unstyled">
              <ItemList
                key={value.id}
                name={value.Nombre}
                description={value.Descripcion}
                price={value.Precio}
              />
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Pizzas;
