import React from 'react';
import ItemList from '../components/ItemList';

function MenusKebab() {

  const menusList1 = [
    { name: 'Kebab', description: 'Carne de ternera, incluye patatas fritas y bebida', price: '7,00' },
    { name: 'Shawarma', description: 'Carne de pollo, incluye patatas fritas y bebida', price: '7,00' },
    { name: 'Mixto', description: 'Carne de pollo y ternera, incluye patatas fritas y bebida', price: '7,00' }
  ];
  const menusList2 = [
    { name: 'Durum', description: 'Carne de pollo o ternera, incluye patatas fritas y bebida', price: '8,00' },
    { name: 'Pizza Turca', description: 'Carne de pollo o ternera, incluye patatas fritas y bebida', price: '9,00' }
  ];


  return (
    <div id="pizzas" className="menu-section">
      <div className="row">
        <div className="col-md-6">
          <ul className="list-unstyled">
            {menusList1.map((menu, index) => (
              <ItemList
                key={index}
                name={menu.name}
                description={menu.description}
                price={menu.price}
              />
            ))}
          </ul>
        </div>
        <div className="col-md-6">
          <ul className="list-unstyled">
            {menusList2.map((menu, index) => (
              <ItemList
                key={index}
                name={menu.name}
                description={menu.description}
                price={menu.price}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default MenusKebab;
